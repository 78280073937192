export default class ContentCardBehavior {

    #protocol = "https:";
    #domain = "braze.lapresse.ca";
    #action = "/triggerevent";
    #url;
    #card;

    constructor(card) {
        this.#card = card;
        this.#url = new URL(this.#card.url);
    }

    shouldShowButtonWithTriggerEvent = () => {
        return this.#shouldDisplayButton() && this.#shouldTriggerEvent();
    };

    shouldShowUrlButton = () => {
        return this.#shouldDisplayButton() && this.#hasValidRedirectUrl();
    };

    shouldShowUrlImage = () => {
        return this.#hasValidRedirectUrl();
    };

    #shouldTriggerEvent = () => {
        return this.#url.protocol.toLowerCase() === this.#protocol &&
               this.#url.hostname.toLowerCase() === this.#domain &&
               this.#url.pathname.toLowerCase() === this.#action;
    };

    #shouldDisplayButton = () => {
        return !!this.#card.extras.buttonText;
    };

    #hasValidRedirectUrl = () => {
        return this.#url.protocol.toLowerCase() === this.#protocol &&
               this.#url.hostname.toLowerCase() !== this.#domain;
    };

    getTriggerEventId = () => {
        return this.#url.searchParams.get("id");
    };
}
