/**
 * This class detects if the originating request comes from level-3 of LaPresse+
 * It detects it by inspecting a specific parameter in URL (that is injected by the app)
 *
 * When detected as LPP Level 3, captures the parameter and store a state in a session storage.
 * Then remove the parameter so if URL is shared with friends, the LPP Level 3 state is not shared with it.
 *
 * Detecting LPP Level 3 is usefull for controling campaign targeting.
 */
export default class ReferrerDetector {

    constructor(window, sessionStorage) {
        this._window = window;
        this._domDocument = window.document;
        this._sessionStorage = sessionStorage;
        this._appReferrer = null;
    }

    evaluateReferrer = () => {
        var appReferrer = this._getApplicationReferrerFromLocation(this._domDocument.location);
        if (appReferrer) {
            this._sessionStorage.setItem("appReferrer", appReferrer);
            this._removeReferrerFromUrl(this._domDocument.location, appReferrer);
        }

        if (this._sessionStorage.getItem("appReferrer")) {
            this._appReferrer = this._sessionStorage.getItem("appReferrer");
        }

        return this._appReferrer;
    };

    _getApplicationReferrerFromLocation = (pageLocation) => {
        var result = pageLocation.search.match(/(?:&|\?)referrer=(?<ref>lpp)(?:&|$)/);
        if (result) {
            return result.groups.ref;
        }

        return "";
    };

    _removeReferrerFromUrl = (pageLocation, appReferrer) => {
        var param;
        if (pageLocation.search !== "") {
            var searchString = pageLocation.search.substring(1);
            var paramList = searchString.split("&");
            for (var i = paramList.length - 1; i >= 0; i -= 1) {
                param = paramList[i].split("=");
                if (param[0] === "referrer" && param[1] === appReferrer) {
                    paramList.splice(i, 1);
                }
            }

            var newParam = (paramList.length > 0) ? "?" + paramList.join("&") : "";

            this._window.history.replaceState({}, this._domDocument.title, pageLocation.pathname + newParam);
        }
    };

}
