import * as braze from "@braze/web-sdk";
import AuthFirebaseBridge from "./Braze/AuthFirebaseBridge";
import ContentCardFilter from "./Braze/ContentCardFilter";
import ContentCardRepository from "./Braze/ContentCardRepository";
import ContentCardView from "./Braze/ContentCardView";
import CustomHTMLBridge from "./Braze/CustomHTMLBridge";
import CustomHTMLSlideUp from "./Braze/CustomHTMLSlideUp";
import NewsletterAdapter from "./Braze/NewsletterAdapter";
import ReferrerDetector from "./Braze/ReferrerDetector";
import {getCpuArchitecture} from "./User/UserSystemInfoProvider";

/**
 * This is wrapper class over Braze SDK.
 * The intent is to allow us to control the display of a message, injection of API within custom html messages, etc.
 */
export class NGBraze {

    internalBraze;

    constructor(domDocument, logger = null) {
        this._logger = logger || console;
        this._domDocument = domDocument;
        this._pageViewTriggered = false;
        this._appReferrer = new ReferrerDetector(window, window.sessionStorage).evaluateReferrer();
        this.internalBraze = braze;
        this._contentCardRepository = new ContentCardRepository(braze, this._logger);
        this._contentCardSpots = [];
    }

    init(lpauth, analytics, clientKey, baseUrl, enableLogging, sessionTimeoutInSeconds) {

        braze.initialize(clientKey, {
            baseUrl: baseUrl,
            allowUserSuppliedJavascript: true,
            minimumIntervalBetweenTriggerActionsInSeconds: 0,
            enableLogging: enableLogging,
            sessionTimeoutInSeconds: sessionTimeoutInSeconds,
            requireExplicitInAppMessageDismissal: true,
        });

        this._domDocument.addEventListener("visibilitychange", () => {
            braze.requestImmediateDataFlush((isSuccess) => {
                if (!isSuccess) {
                    this._logger.warn(
                        "%c🟡 nuglif%c braze immediate data flush %o",
                        "color: #1EAEFD;background: #000; padding: 0 1em 0 0; border-radius:1em; font-size: 1em;",
                        "",
                        isSuccess ? "succeed" : "failed",
                    );
                }
            });
        });

        window.newsletterAdapter = new NewsletterAdapter(braze, lpauth, navigator);
        window.authBridge = new AuthFirebaseBridge(braze, lpauth);

        new CustomHTMLBridge(window, window.authBridge);

        this._initAnalytics(analytics);
        window.nuglif.ngApp.deviceInstanceId.get(function(id) {
            window.ngBraze.updateDeviceInstanceId(id);
        });

        this.automaticallyShowMessages();
        this.openSession();
        this._addConsentChangedListener();
        this._contentCardRepository.refreshCards().finally(this._fillAllContentCardSpots);
    }

    _initAnalytics(analytics) {
        analytics.state.setBrazeDeviceId(braze.getDeviceId());
    }

    _addConsentChangedListener() {
        window.nuglif.consentHandler.onConsentChanged((consent) => {
            braze.getUser().setCustomUserAttribute("consentement_sollicitation_mktg", consent.productAndPhilanthropy);
        });
    }

    updateDeviceInstanceId(deviceInstanceId) {
        this._logger.debug("LP-Braze: add device_instance_id alias of \"" + deviceInstanceId + "\"");
        braze.getUser().addAlias(deviceInstanceId, "device_instance_id");
    }

    automaticallyShowMessages = () => {
        this._logger.debug("LP-Braze: subscribe to in app message");
        braze.subscribeToInAppMessage(this._showInAppMessage);
    };
    _fillAllContentCardSpots = () => {
        this._logger.debug("LP-Braze: filling all contentCard spots");
        this._contentCardSpots.forEach(({location, filters, successCallback, errorCallback}) => {
            try {
                this._fillContentCardSpot(location, filters);
                successCallback && successCallback();
            } catch (error) {
                this._logger.debug("LP-Braze: Problem trying to show content card - " + error.message);
                errorCallback && errorCallback(error);
            }
        });
    };
    registerContentCardSpot = ({location, filters, successCallback, errorCallback}) => {
        this._logger.debug("LP-Braze: Registering contentCard spot: ", location);
        if (this._contentCardRepository.areContentCardsRefreshed) {
            try {
                this._fillContentCardSpot(location, filters);
                successCallback && successCallback();
            } catch (error) {
                this._logger.debug("LP-Braze: Problem trying to show content card - " + error.message, location);
                errorCallback && errorCallback(error);
            }
        } else {
            this._contentCardSpots.push({location, filters, successCallback, errorCallback});
        }
    };

    _fillContentCardSpot = (location, filters) => {
        let contentCardFilter = new ContentCardFilter();
        if (filters.storyId) {
            contentCardFilter.setStoryId(filters.storyId);
        }
        if (filters.storyFormat) {
            contentCardFilter.setStoryFormat(filters.storyFormat);
        }
        if (filters.section) {
            contentCardFilter.setSectionId(filters.section.id);
            contentCardFilter.setRootSectionId(filters.section.rootSectionId);
        }
        contentCardFilter.setLocation(location);
        let contentCardView = new ContentCardView(location, braze, this._contentCardRepository, contentCardFilter, this._logger);
        contentCardView.show();
    };

    _showInAppMessage = message => {
        this._logger.debug("LP-Braze: show message");

        if (message.extras.CUSTOM_DISPLAY_TYPE === "slide_up") {
            this._logger.debug("LP-Braze: custom slide up");

            var NGMessage = new CustomHTMLSlideUp(braze, this._domDocument, window.authBridge);

            this._displayedMessage = NGMessage;
            NGMessage.show(message);
        } else {
            //standard message. Let Braze do the handling
            this._logger.debug("LP-Braze: standard message");
            this._displayedMessage = message;
            braze.showInAppMessage(message);
        }
    };

    openSession = () => {
        braze.openSession();
    };

    getFeatureFlag(flagName) {
        const featureFlag = braze.getFeatureFlag(flagName);
        const loggedFeatureFlagImpression = braze.logFeatureFlagImpression(flagName);
        this._logger.debug("logFeatureFlagImpression", loggedFeatureFlagImpression);

        return featureFlag;
    }

    handlePageView = async({contentCategory, contentType, isLoggedIn, userAgent, podcastId=null}) => {
        if (this._pageViewTriggered) {
            return;
        }

        const urlSearchParams = new URLSearchParams(window.location.search);
        const customEventProperties = {
            contentCategory: contentCategory,
            contentType: contentType,
            isLoggedIn: isLoggedIn,
            podcastId: podcastId,
            appReferrer: this._appReferrer,
            cpuArchitecture: await getCpuArchitecture(),
            userAgent: userAgent,
            utmSource: urlSearchParams.get("utm_source"),
            utmMedium: urlSearchParams.get("utm_medium"),
            utmCampaign: urlSearchParams.get("utm_campaign"),
            utmContent: urlSearchParams.get("utm_content"),
        };

        this._logger.debug("LP-Braze: send WEB_PAGE_VIEW event: %o", customEventProperties);
        braze.logCustomEvent("WEB_PAGE_VIEW", customEventProperties);

        this._pageViewTriggered = true;
    };

    //DEBUG OR TEST FUNCTION

    printDeviceId() {
        console.log(braze.getDeviceId());
    }

    printExternalId() {
        console.log(braze.getUser().getUserId());
    }
}
