/**
 * This class provides an mechanism to communicate with a Braze Custom HTML message that is displayed into and IFRAME.
 * The technic is to communicate through window messages.
 *
 * We must be very cautious with operations to avoid exploits as any browser window can listen to those events.
 */
export default class CustomHTMLBridge {

    constructor(window, authBridge, logger = null) {
        this._logger = logger || console;
        this._logger.debug("CustomHTMLBridge: initialize");

        this._domWindow = window;
        this._domDocument = this._domWindow.document;
        this._authBridge = authBridge;

        this._domWindow.addEventListener("message", this.customHTMLModalEventListener.bind(this), false);
    }

    customHTMLModalEventListener(event) {
        const PREFIX = "LP.BRZ:";

        // The origin of the event must be validated in order to avoid/reduce risks of exploits.
        // With Braze messages, the origin will always be the same as our current document.
        if (event.origin !== this._domDocument.location.origin) {
            return;
        }

        let data = event.data;
        if ("string" !== typeof data) {
            return;
        }

        if (!data.startsWith(PREFIX)) {
            return;
        }

        let message = {name: "unknown"};
        try {
            //Since we also integrate other 3rd parties, we may receive messages from them that we do not want to handle.
            //for example, we do receive messages from a twitter component (and it generated errors in the JS console).
            //We are using a prefix as a mean for namespacing the message.
            //
            //We do not make use of other parameters like "object transfer" as its not supported by the same number of browsers.
            //
            //format: LP.BRZ:<json string>
            message = JSON.parse(data.substring(PREFIX.length));
        } catch (err) {
            console.error(err);
            console.warn("CustomHTMLBridge: skipping message processing due to previous exception.");
            return;
        }

        this._logger.debug("CustomHTMLBridge: processing message %o", message.name);

        switch (message.name) {
            case "TRIGGER-LOGIN-FORM":
                this._handleTriggerLoginForm(message.params);
                break;
            default:
                break;
        }
    }

    _handleTriggerLoginForm() {
        this._authBridge.triggerLoginForm();
    }
}
