import ContentCardBehavior from "./ContentCardBehavior";
/** @typedef {import("@braze/web-sdk")} Braze */

export default class ContentCardView {

    #location;
    #braze;
    #contentCardDomElement;
    #contentCardRepository;
    #logger;
    #loggedCardImpression = false;
    #filter;

    /**
     * @param location
     * @param {Braze} braze
     * @param contentCardRepository
     * @param filter
     * @param logger
     */
    constructor(location, braze, contentCardRepository, filter, logger) {
        this.#location = location;
        this.#braze = braze;
        this.#contentCardRepository = contentCardRepository;
        this.#logger = logger;
        this.#filter = filter;
    }

    show = () => {
        this.#logger.debug("LP-Braze: filling content card spot:", this.#location);
        let cards = this.#contentCardRepository.getCards();
        if (!cards.length) {
            throw new Error("No content cards from Braze");
        }

        const card = this.#filter.getCard(cards);
        if (!card) {
            throw new Error("No content cards applies to spot");
        }
        this.#createContendCardDomElement(card);
        this.#registerContentCardClick(card);
        this.#registerContentCardImpression(card);
    };

    #registerContentCardClick = card => {
        const contentCardAnchors = this.#contentCardDomElement.getElementsByTagName("a");
        for (const el of contentCardAnchors) {
            el.addEventListener("click", () => {
                this.#braze.logContentCardClick(card);
                this.#handleDismissal(card);
                this.#logger.debug("LP-Braze: Logged event card click to braze.", this.#location);
            });
        }
    };

    #registerContentCardImpression = card => {

        if (this.#isAtLeastOnePixelInViewport(this.#contentCardDomElement)) {
            this.#braze.logContentCardImpressions([card]);
            this.#loggedCardImpression = true;
            this.#logger.debug("LP-Braze: Logged event card impression to braze.", this.#location);
            return;
        }

        const onScroll = () => {
            if (this.#isAtLeastOnePixelInViewport(this.#contentCardDomElement)) {
                this.#braze.logContentCardImpressions([card]);
                this.#loggedCardImpression = true;
                document.removeEventListener("scroll", onScroll);
                this.#logger.debug("LP-Braze: Logged card impression to braze (after scroll event).", this.#location);
            }
        };

        document.addEventListener("scroll", onScroll, {
            passive: true,
        });
    };

    #createContendCardDomElement = card => {
        this.#contentCardDomElement = document.querySelector(`[data-content-card-location="${this.#location}"]`);
        const contentCardBehavior = new ContentCardBehavior(card);

        const cssClasses = ["contentCard--visible"];
        const style = card?.extras?.style ?? "default";
        cssClasses.push("contentCard--" + style);

        if (contentCardBehavior.shouldShowButtonWithTriggerEvent()) {
            this.#showWithTriggerEventButton(card, contentCardBehavior.getTriggerEventId(), style);
            cssClasses.push("contentCard--withButton");
        } else if (contentCardBehavior.shouldShowUrlButton()) {
            this.#showWithUrlButton(card, style);
            cssClasses.push("contentCard--withButton");
        } else if (contentCardBehavior.shouldShowUrlImage()) {
            this.#showWithImage(card);
            cssClasses.push("contentCard--imageOnly");
        } else {
            throw new Error("Content card configuration not supported.");
        }

        this.#contentCardDomElement.classList.add(...cssClasses);
    };

    #handleDismissal = card => {
        if (card?.extras?.alwaysVisible === "true") {
            return;
        }

        this.#braze.logCardDismissal(card);
    };

    #showWithImage = card => {
        this.#contentCardDomElement.innerHTML = `
                <a class="contentCard__link" href="${card.url}" target="_blank">
                    <img class="contentCard__image" src="${card.imageUrl}" />
                </a>
            `;
    };

    #showWithUrlButton = (card, style) => {
        this.#contentCardDomElement.innerHTML = `
                <img class="contentCard__image" src="${card.imageUrl}" />
                <div class="contentCard__btn">
                    <a href="${card.url}" class="contentCard__link contentCard__link--${style}" rel="noopener" target="_blank">${card.extras.buttonText}</a>
                </div>
            `;
    };

    #showWithTriggerEventButton = (card, contextId, style) => {
        this.#contentCardDomElement.innerHTML = `
                <img class="contentCard__image" src="${card.imageUrl}" />
                <div class="contentCard__btn">
                    <a class="contentCard__link contentCard__link--${style}" id="contentCard__link" data-tracking="false">${card.extras.buttonText}</a>
                </div>
            `;

        document.getElementById("contentCard__link").addEventListener("click", () => {
            this.#callToTriggerEventClick(contextId);
        });
    };

    #callToTriggerEventClick = (contextId) => {
        const customEventProperties = {id: encodeURI(contextId)};
        this.#logger.debug('LP-Braze: send EVENT_LPCA_TRIGGER event ("' + encodeURI(contextId) + '")', this.#location);
        this.#braze.logCustomEvent("EVENT_LPCA_TRIGGER", customEventProperties);
    };

    #isAtLeastOnePixelInViewport = el => {
        const rect = el.getBoundingClientRect();
        return rect.top >= -el.offsetHeight &&
            (
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) ||
                rect.top <= (window.innerHeight || document.documentElement.clientHeight)
            );
    };

    hasContentCardLoggedImpression = () => {
        return this.#loggedCardImpression;
    };
}
