import ScrollControl from "./ScrollControl";

/**
 * This class is a Display Wrapper of Custom HTML sent from Braze with the intent of displaying like a bottom banner (usually called slide-up or drawer when animated)
 * It takes the HTML from the braze message and renders it by itself.
 * It offers some functionnalities (Javascript API) to the message so it can:
 * - receive a notification of login
 * - close itself
 * - log clicks
 * - update braze user profile with custom attributes
 * - raise braze custom events
 *
 */
export default class CustomHTMLSlideUp {

    constructor(braze, domDocument, authBridge) {
        this._domDocument = domDocument;
        this._braze = braze;
        this._authBridge = authBridge;

        this._containerDiv = null;
        this._scrollControl = new ScrollControl(domDocument);
    }

    show = (brazeMessage) => {

        this._createContainerDiv();

        var braze = this._braze;
        var authBridge = this._authBridge;
        var host = this;
        var container = this._containerDiv;

        window.NGBridge = {
            message : {
                logClick: (button) => {braze.logInAppMessageHtmlClick(brazeMessage, button);},
                close: () => {host._destroy();},
                disableScroll: () => {host._disableScroll();},
                setMessageHeight: (height) => {container.style.height = height;}
            },
            getUser : braze.getUser,
            auth : authBridge
        };

        this._containerDiv.innerHTML = this._getMessageHead(brazeMessage.message) + this._getMessageBody(brazeMessage.message);
        this._domDocument.body.appendChild(this._containerDiv);
        this._activateScriptTags(this._containerDiv);

        braze.logInAppMessageImpression(brazeMessage);
    };

    _activateScriptTags(node) {
        if ( node.tagName === "SCRIPT" ) {
            node.parentNode.replaceChild( this._recreateScriptElement(node) , node );
        }
        else {
            var i = -1;
            var children = node.childNodes;
            while ( ++i < children.length ) {
                this._activateScriptTags( children[i] );
            }
        }

        return node;
    }
    _recreateScriptElement(scriptNode) {
        var script  = this._domDocument.createElement("script");
        script.text = scriptNode.innerHTML;

        var i = -1; var attrs = scriptNode.attributes; var 
            attr;
        while ( ++i < attrs.length ) {
            script.setAttribute( (attr = attrs[i]).name, attr.value );
        }
        return script;
    }

    _disableScroll = () => {
        this._scrollControl.disable();
    };

    _destroy = () => {
        this._scrollControl.reset();
        this._domDocument.body.removeChild(this._containerDiv);
    };

    _createContainerDiv() {
        var div = this._domDocument.createElement("div");

        div.style.position = "fixed";
        div.style.bottom = "0px";
        div.style.height = "100px";
        div.style.width = "100%";
        div.style.backgroundColor = "transparent";
        div.style.border = "none";
        div.style.overflow = "auto";
        div.style.boxSizing = "border-box";
        div.style.boxShadow = "0 0 4px rgba(0,0,0,.3)";
        div.style.lineHeight = "normal";
        div.style.letterSpacing = "normal";
        div.style.zIndex = 1050;
        div.style.maxWidth = "100%";
        div.style.display = "inline-block";
        div.style.pointerEvents = "all";
        div.style.color = "#333";
        div.style.webkitTapHighlightColor = "transparent";
        div.style.outline = "none";
        div.style.fontSize = "16px";

        this._containerDiv = div;
    }

    _getMessageHead(message) {
        return message.match(/<head>([\s\S]+)<[/]head>/mi)[1];

    }

    _getMessageBody(message) {
        return message.match(/<body>([\s\S]+)<[/]body>/mi)[1];
    }
}
