/**
 * An adapter class of LPAuth over Firebase to Braze.
 * It bridges a "LoginSuccess" event to the message.  So the message can perform a specific action when the user logs in.
 * As an example: The Login Wall Slide-Up closes itself when the user logs in.
 */
export default class AuthFirebaseBridge {

    constructor(braze, lpauth, logger = null) {
        this._logger = logger || console;
        this._loginSuccessCallbacks = [];

        this._braze = braze;
        this._lpauth = lpauth;

        this._lpauth.onAuthStateChanged(this._lpauth_authStateChanged);
        this._lpauth.onLoginSuccess(this._lpauth_loginSuccess);
        this._lpauth.onLoginCancelled(this._lpauth_loginCancelled);
    }

    triggerLoginForm() {
        this._lpauth.login();
    }

    _lpauth_authStateChanged = () => {
        //NOTE: (PGILL)
        //      This function has been added in order to reconnect an Anonymous Braze Profile with the connected user (this is done by calling Braze's changeUser function).
        //
        //      This situation should not happen in normal situation because the "onLoginSuccess" event handler perform the changeUser Braze operation as soon as the user logs in.
        //      However it is added as a safety precaution AND to correct some profiles that has this problem because of bug (now fixed) in the login library
        //      It may be a good idea to leave it there as to create resilience.
        //
        //      By verifying if the Braze externalId is specified on every request we will reconnect the profiles without the user being forced to re-login.
        const id = this._braze.getUser().getUserId();
        if (this._lpauth.currentUser() && id !== this._lpauth.currentUser().uid) {
            this._logger.debug('LP-BrazeAuthFirebaseBridge: fixes Braze identity for "' + this._lpauth.currentUser().uid + '"');
            this._braze.changeUser(this._lpauth.currentUser().uid);
        }
    };

    _lpauth_loginSuccess = (event) => {
        this._logger.debug('LP-BrazeAuthFirebaseBridge: loginSuccess for "' + event.user.uid + '"');

        this._braze.logCustomEvent("WEB_LOGIN_CLOSE", {result: "success"});
        this._braze.changeUser(event.user.uid);

        this._raiseOnLoginSuccess();
    };

    _lpauth_loginCancelled = () => {
        this._logger.debug("LP-BrazeAuthFirebaseBridge: loginCancelled");

        this._braze.logCustomEvent("WEB_LOGIN_CLOSE", {result: "cancel"});
    };

    _raiseOnLoginSuccess = () => {
        this._logger.debug("LP-BrazeAuthFirebaseBridge: " + this._loginSuccessCallbacks.length.toString() + " callback(s) registered");

        for (var i = 0; i < this._loginSuccessCallbacks.length; i++) {
            this._loginSuccessCallbacks[i]();
        }
    };

    onLoginSuccess(callback) {
        this._logger.debug("LP-BrazeAuthFirebaseBridge: registering callback for login success");
        this._loginSuccessCallbacks.push(callback);
    }
}
