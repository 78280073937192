/**
 * This is an internal support class to enable/disable scrolling when displaying CustomHTMLSlideUp.
 */
export default class ScrollControl {

    constructor(domDocument) {
        this._domDocument = domDocument;
        this._scrollReset = null;
    }

    disable = () => {
        if (this._scrollReset !== null) {
            return;
        }

        this._scrollReset = {
            overflow: this._domDocument.body.style.overflow,
            touchAction: this._domDocument.body.style.touchAction
        };

        this._domDocument.body.style.overflow = "hidden";
        this._domDocument.body.style.touchAction = "none";

        this._domDocument.body.addEventListener("touchmove", this._touchMoveGobber, {capture: true, passive: false});
    };

    reset = () => {
        if (this._scrollReset !== null) {
            this._domDocument.body.style.overflow = this._scrollReset.overflow;
            this._domDocument.body.style.touchAction = this._scrollReset.touchAction;

            this._domDocument.body.removeEventListener("touchmove", this._touchMoveGobber, {capture: true, passive: false});

            this._scrollReset = null;
        }
    };

    _touchMoveGobber = (evt) => {
        // Stop propagation du touch move pour bloquer certaines situations lorsque le touch move débute sur le message
        evt.preventDefault();
        evt.stopImmediatePropagation();
    };
}
