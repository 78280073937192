export default class ContentCardRepository {

    #braze;
    #logger;
    areContentCardsRefreshed = false;

    constructor(braze, logger) {
        this.#braze = braze;
        this.#logger = logger;
    }

    getCards = () => {
        return this.#braze.getCachedContentCards().cards ?? [];
    };

    refreshCards = () => {
        return new Promise((resolve, reject) => {
            const timeoutId = setTimeout(() => {
                this.#logger.debug("ContentCard: Repository - requestContentCardsRefresh TIMEOUT.");
                reject();
            }, 3000);
            this.#braze.requestContentCardsRefresh(
                () => {
                    clearTimeout(timeoutId);
                    this.#logger.debug("ContentCard: Repository - requestContentCardsRefresh SUCCESS.", this.getCards());
                    resolve();
                },
                () => {
                    clearTimeout(timeoutId);
                    this.#logger.debug("ContentCard: Repository - requestContentCardsRefresh FAIL.");
                    reject();
                },
            );
        }).finally(() => {
            this.areContentCardsRefreshed = true;
        });
    };
}
