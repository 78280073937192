/**
 * An adapter class of Braze Newsletter.
 */

export default class NewsletterAdapter {

    constructor(braze, lpauth, navigator, logger = null) {
        this._logger = logger || console;
        this._braze = braze;
        this._lpauth = lpauth;
        this._navigator = navigator;

        this._subscribeToEventNewsletterSubscriptionCompleted();
    }

    async identifyFromEmail(email) {
        let account = await this._getExistingAccount(email);
        if (!account) {
            account = await this._createGhostAccount(email);
        }
        this._identifyBrazeProfile(account.id, email);
    }

    async _getExistingAccount(email) {
        let response = await this._lpauth.authService().findFromEmail(email);
        return response?.data?.items?.[0];
    }

    async _createGhostAccount(email) {
        let account =  await this._lpauth.authService().createGhostAccount(email);
        if (!account) {
            throw new Error();
        }
        return account;
    }

    subscribeToGroupWithEmail(newsletter, email) {
        this._addToSubscriptionGroup(newsletter);
        this._publishSubscriptionCompletedEvent(newsletter.id, email);
    }

    _addToSubscriptionGroup(newsletter) {
        const result = this._braze.getUser().addToSubscriptionGroup(newsletter.subscriptionGroupId);
        this._braze.requestImmediateDataFlush(() => {});

        if (!result) {
            console.warn("Erreur lors de l'inscription à l'infolettre %o", newsletter);
            throw new Error("Problem adding to subscription group." + newsletter?.subscriptionGroupId);
        }
    }

    _identifyBrazeProfile(readerId, email) {
        this._braze.changeUser(readerId);
        this._braze.getUser().setEmail(email);
    }

    _notifyNewsletterSubscription = (newsletterId, isLoggedIn, userAgent) => {
        const customEventProperties = { newsletterId: newsletterId, isLoggedIn: isLoggedIn, userAgent};
        this._logger.debug('LP-Braze: send WEB_NEWSLETTER_SUBSCRIPTION_COMPLETE event ("' + newsletterId + '", "' + isLoggedIn + '", "' + userAgent + '")');
        this._braze.logCustomEvent("WEB_NEWSLETTER_SUBSCRIPTION_COMPLETED", customEventProperties);
    };

    _subscribeToEventNewsletterSubscriptionCompleted() {
        window.addEventListener("newsletter.subscriptionCompleted", function(event) {
            this._notifyNewsletterSubscription(event.detail.newsletterId, this._lpauth.isLoggedIn(), this._navigator.userAgent);
        }.bind(this), false);
    }

    _publishSubscriptionCompletedEvent(newsletter, email) {
        window.dispatchEvent(new CustomEvent("newsletter.subscriptionCompleted", {
            "detail": {
                "userEmail": email,
                "newsletterId": newsletter,
            },
        }));
    }
}
