export default class ContentCardFilter {

    #cards;
    #storyId;
    #storyFormat; // regular, flashnews, chronicle, opinion...
    #sectionId;
    #rootSectionId;
    #location;

    setStoryId = storyId => {
        this.#storyId = storyId;
    };

    setStoryFormat = storyFormat => {
        this.#storyFormat = storyFormat;
    };

    setSectionId = sectionId => {
        this.#sectionId = sectionId;
    };

    setRootSectionId = rootSectionId => {
        this.#rootSectionId = rootSectionId;
    };
    setLocation = location => {
        this.#location = location;
    };

    getCard = cards => {
        this.#cards = cards;
        this.#orderByPriority();
        for (let card of cards) {
            if (this.#shouldShow(card)) {
                return card;
            }
        }

        return null;
    };

    #orderByPriority = () => {
        this.#cards.sort((a, b) => {

            let aPriority = 1000;
            let bPriority = 1000;

            if (a.extras && a.extras.priority) {
                aPriority = this.#stringToPriority(a.extras.priority);
            }

            if (b.extras && b.extras.priority) {
                bPriority = this.#stringToPriority(b.extras.priority);
            }

            if (aPriority < bPriority) {
                return 1;
            }
            if (aPriority > bPriority) {
                return -1;
            }

            return 0;
        });
    };

    #stringToPriority = string => {
        return this.#isStringOnlySpaces(string) ? 1000 : Number(string) || 0;
    };

    #isStringOnlySpaces = string => {
        return /^\s*$/.test(string);
    };

    #shouldShow = card => {
        if(!this.#isCorrectLocation(card)) {
            return false;
        }
        if (this.#isExcluded(card)) {
            return false;
        }
        return this.#isIncluded(card);
    };

    #isCorrectLocation = (card) => {
        if (!card.extras || !card.extras.location) {
            return false;
        }

        let isLocationIncluded = false;
        card.extras.location.split(",").forEach(location => {
            if (this.#location.toLowerCase().trim() === location.toLowerCase().trim()) {
                isLocationIncluded = true;
            }
        });
        return isLocationIncluded;
    };

    #isExcluded = card => {

        if (!card.extras || !card.extras.exclusions) {
            return false;
        }

        const exclusions = card.extras.exclusions;
        let isExcluded = false;

        exclusions.split(",").forEach(element => {
            const key = element.split(":")[0].toLowerCase().trim();
            const value = element.split(":")[1].toLowerCase().trim();

            switch (key) {
                case "section":
                    if (this.#sectionId === value || this.#rootSectionId === value) {
                        isExcluded = true;
                    }
                    break;
                case "format":
                    if (this.#storyFormat === value) {
                        isExcluded = true;
                    }
                    break;
                case "storyid":
                    if (this.#storyId === value) {
                        isExcluded = true;
                    }
                    break;
                case "platform":
                    if ("lpca" === value) {
                        isExcluded = true;
                    }
                    break;
                default:
                    break;
            }
        });

        return isExcluded;
    };

    #isIncluded = card => {
        if (!card.extras || !card.extras.inclusions) {
            return true;
        }

        const inclusions = card.extras.inclusions;

        let isIncluded = false;

        inclusions.split(",").forEach(element => {
            const key = element.split(":")[0].toLowerCase().trim();
            const value = element.split(":")[1].toLowerCase().trim();

            switch (key) {
                case "section":
                    if (this.#sectionId === value || this.#rootSectionId === value) {
                        isIncluded = true;
                    }
                    break;
                case "format":
                    if (this.#storyFormat === value) {
                        isIncluded = true;
                    }
                    break;
                case "storyid":
                    if (this.#storyId === value) {
                        isIncluded = true;
                    }
                    break;
                case "platform":
                    if ("lpca" === value) {
                        isIncluded = true;
                    }
                    break;
                default:
                    break;
            }
        });

        return isIncluded;
    };
}
